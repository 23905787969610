import { appendSpinnerToFormButtons } from "../../spinner"
import { fetchPasswordInputValidation } from "../api"
import "./passwordInputFieldNoComponents.scss"
import { querySelectorNonNull } from "../../document"

class PasswordInputFieldNoComponents {
    private readonly $passwordInputElement: HTMLInputElement
    private readonly $inputHintElement: HTMLElement
    private readonly $togglePasswordTextShow: HTMLElement
    private readonly $togglePasswordTextHide: HTMLElement
    private onErrorStatusChangeCallback: Function | undefined
    private onErrorStatusChangeCallbackCallee: Object | undefined
    private onShowPasswordToggleCallback: Function | undefined
    private hasError = false
    private isPasswordReadable = false
    private readonly $document: Document
    private readonly trackTogglePasswordShowClick: () => void
    private readonly trackTogglePasswordHideClick: () => void

    constructor(document: Document, trackTogglePasswordShowClick: () => void, trackTogglePasswordHideClick: () => void) {
        this.$document = document
        this.trackTogglePasswordShowClick = trackTogglePasswordShowClick
        this.trackTogglePasswordHideClick = trackTogglePasswordHideClick
        this.$inputHintElement = querySelectorNonNull(document, ".fti_js_passwordInput_hint")
        this.$passwordInputElement = querySelectorNonNull(document, ".fti_js_passwordInput")
        this.$togglePasswordTextShow = querySelectorNonNull(
            document,
            ".fti_js_togglePasswordContainer .fti_js_passwordInput_togglePassword_show",
        )
        this.$togglePasswordTextHide = querySelectorNonNull(
            document,
            ".fti_js_togglePasswordContainer .fti_js_passwordInput_togglePassword_hide",
        )
    }

    registerEventListeners(onShowPasswordToggle?: Function) {
        this.$passwordInputElement.addEventListener("focusout", this.onFocusOut)
        this.$togglePasswordTextShow.addEventListener("click", this.onClickToggle)
        this.$togglePasswordTextHide.addEventListener("click", this.onClickToggle)
        this.registerSubmitButtonSpinner()
        this.registerTrackingForPasswordToggle()

        if (onShowPasswordToggle) {
            this.onShowPasswordToggleCallback = onShowPasswordToggle
        }
    }

    private onClickToggle = async () => {
        this.togglePasswordDisplay()
        this.onShowPasswordToggleCallback?.call(this)
    }

    togglePasswordDisplay() {
        this.updatePasswordInputType()
        this.updateToggleButton()
        this.isPasswordReadable = !this.isPasswordReadable
    }

    private updatePasswordInputType() {
        const newTypeAttribute = this.isPasswordReadable ? "password" : "text"
        this.$passwordInputElement.setAttribute("type", newTypeAttribute)
    }

    private updateToggleButton() {
        if (this.isPasswordReadable) {
            this.$togglePasswordTextShow.classList.remove("invisible")
            this.$togglePasswordTextHide.classList.add("invisible")
        } else {
            this.$togglePasswordTextShow.classList.add("invisible")
            this.$togglePasswordTextHide.classList.remove("invisible")
        }
    }

    private onFocusOut = async () => {
        this.$passwordInputElement.removeEventListener("input", this.onInput)
        this.$passwordInputElement.addEventListener("input", this.onInput)
        await this.validateInput()
    }

    private onInput = async () => {
        await this.validateInput()
    }

    private async validateInput() {
        try {
            const responseData = await fetchPasswordInputValidation(this.$passwordInputElement.value)
            const errorMessage = responseData.errorMessage
            this.hasError = errorMessage != null && errorMessage != ""
            if (this.hasError) {
                this.showErrorHint(errorMessage)
            } else {
                this.hideErrorHint()
            }

            this.onErrorStatusChange()
        } catch (exception) {
            console.error(exception)
        }
    }

    private showErrorHint(message?: string) {
        if (message != null) {
            this.$inputHintElement.innerText = message
        }

        const parentClassList = this.$inputHintElement.parentElement?.classList
        parentClassList?.remove("pl_input--hint")
        parentClassList?.add("pl_input--invalid")
        this.$inputHintElement.style.display = "block"
    }

    private hideErrorHint() {
        const hasHintClass = this.$inputHintElement.parentElement?.classList.contains("pl_input--invalid")
        if (hasHintClass) {
            this.$inputHintElement.style.display = "none"
            this.$inputHintElement.parentElement?.classList.remove("pl_input--invalid")
        }
    }

    private onErrorStatusChange() {
        this.onErrorStatusChangeCallback?.call(this, !this.hasError, this.onErrorStatusChangeCallbackCallee)
    }

    private registerSubmitButtonSpinner() {
        appendSpinnerToFormButtons(this.$document, ".fti_js_togglePasswordContainer")
    }

    private registerTrackingForPasswordToggle() {
        if (this.$togglePasswordTextShow) {
            this.$togglePasswordTextShow.addEventListener("click", () => {
                this.trackTogglePasswordShowClick()
            })
        }
        if (this.$togglePasswordTextHide) {
            this.$togglePasswordTextHide.addEventListener("click", () => {
                this.trackTogglePasswordHideClick()
            })
        }
    }
}

export default PasswordInputFieldNoComponents
